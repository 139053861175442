import React from 'react';
import { MdCheck } from 'react-icons/md';
import { useProduct } from '../../../hooks/product';

import { Container } from './styles';
import formatPrice from '../../../utils/formatPrice';

const Summary: React.FC = ({ children }) => {
  const { product, getTotal, installments } = useProduct();

  return (
    <Container>
      {children}

      <aside>
        <div>
          <h2>Sua compra</h2>

          <div>
            <span>{product?.name}</span>
            <small>{product?.duration_in_months} meses de acesso</small>
          </div>

          <ul>
            <li>
              <MdCheck />
              Acesso total à Plataforma Poxalulu
            </li>
            <li>
              <MdCheck />
              {product?.wordings_per_month} Correções por mês
            </li>
            <li>
              <MdCheck />
              {product?.wordings_per_month} Simulados por mês
            </li>
            <li>
              <MdCheck />
              Correções detalhadas
            </li>
            <li>
              <MdCheck />
              Curso completo em vídeo
            </li>
            <li>
              <MdCheck />
              Gráficos por competência
            </li>
            <li>
              <MdCheck />
              Fórum de discussões
            </li>
            <li>
              <MdCheck />
              Materiais didáticos
            </li>
            <li>
              <MdCheck />
              Ranking
            </li>
          </ul>

          {product && product.discount > 0 && (
            <>
              <footer style={{ marginBottom: '4px' }}>
                <span>Subtotal</span>
                <div>
                  <span
                    style={{
                      fontSize: '12px',
                      textDecoration: 'line-through',
                      color: 'red',
                    }}
                  >
                    {formatPrice(product.priceWithoutDiscount)}
                  </span>
                </div>
              </footer>

              <footer style={{ marginBottom: '20px' }}>
                <span>Desconto</span>
                <div>
                  <span style={{ fontSize: '12px' }}>
                    {formatPrice(product.discount)}
                  </span>
                </div>
              </footer>
            </>
          )}

          <footer>
            <span>Total</span>

            <div>
              <small>{getTotal().split(' de ')[0]}</small>
              <span>{getTotal().split(' de ')[1]}</span>
            </div>
          </footer>

          <p
            style={{
              color: '#f6f6fa',
              userSelect: 'none',
            }}
            id="totalValue"
          >
            Valor total: {product ? formatPrice(product.price) : ''}
          </p>
        </div>
      </aside>
    </Container>
  );
};

export default Summary;
